<template>
  <div class="feiting">
    <div class="container" style="display: block; zoom: 0.671756">
      <div class="header">
        <div class="logo">
          <img id="logoimg" src="./images/飞艇/logo_10035.png" width="220" height="57" alt="" />
        </div>
        <div class="position" ref="position">
          <div class="positm" id="pos1" style="background-position: 0px -384px"></div>
          <div class="positm" id="pos2" style="background-position: 0px -192px"></div>
          <div class="positm" id="pos3" style="background-position: 0px -64px"></div>
          <div class="positm" id="pos4" style="background-position: 0px -256px"></div>
          <div class="positm" id="pos5" style="background-position: 0px -128px"></div>
          <div class="positm" id="pos6" style="background-position: 0px -448px"></div>
          <div class="positm" id="pos7" style="background-position: 0px -576px"></div>
          <div class="positm" id="pos8" style="background-position: 0px -320px"></div>
          <div class="positm" id="pos9" style="background-position: 0px 0px"></div>
          <div class="positm" id="pos10" style="background-position: 0px -512px"></div>
        </div>
        <div class="currentdraw">
          期数 : <span id="currentdrawid">{{part_num}}</span>
          <div class="soundBox">
            <button id="soundBtn" class="off"></button>
          </div>
        </div>
      </div>
      <div class="scenary page1" ref="scenary" style="display: block">
        <div class="scenaryitm" id="scenaryitm" ref="scenaryitm" style="left: -1334px">
          <img src="./images/飞艇/scenery.jpg" width="2668" height="170" alt="" />
        </div>
      </div>
      <div class="road page1" ref="road" style="display: block">
        <div class="roadani">
          <div class="roaditm" id="roaditm" ref="roaditm" style="left: -1300px">
            <img src="./images/飞艇/road.jpg" width="2660" height="520" alt="" />
          </div>
        </div>
        <div class="roadstart" id="roadstart" ref="roadstart" style="left: 960px">
          <img src="./images/飞艇/finisher.png" width="249" height="515" alt="" />
        </div>
        <div ref="cars">
          <div class="car1" id="car1" style="left: 1206px; top: -14px">
            <img src="./images/飞艇/car1.png" alt="" />
            <div class="wheel10a" style="display: block">
              <img src="./images/飞艇/wheel.png" alt="" style="animation: initial" />
            </div>
            <div class="wheel10b" style="display: none">
              <img src="./images/飞艇/wheel2.png" alt="" />
            </div>
            <div class="wind" style="opacity: 1; display: block">
              <img src="./images/飞艇/wind.png" width="144" height="53" alt="" />
            </div>
            <div class="flame" style="opacity: 1; display: none">
              <img src="./images/飞艇/flame.png" width="49" height="11" alt="" />
            </div>
          </div>
          <div class="car2" id="car2" style="left: 1197px; top: 24px">
            <img src="./images/飞艇/car2.png" alt="" />
            <div class="wheel10a" style="display: block">
              <img src="./images/飞艇/wheel.png" alt="" style="animation: initial" />
            </div>
            <div class="wheel10b" style="display: none">
              <img src="./images/飞艇/wheel2.png" alt="" />
            </div>
            <div class="wind" style="opacity: 1; display: block">
              <img src="./images/飞艇/wind.png" width="154" height="51" alt="" />
            </div>
            <div class="flame" style="opacity: 1; display: none">
              <img src="./images/飞艇/flame.png" width="49" height="11" alt="" />
            </div>
          </div>
          <div class="car3" id="car3" style="left: 1181px; top: 59px">
            <img src="./images/飞艇/car3.png" alt="" />
            <div class="wheel10a" style="display: block">
              <img src="./images/飞艇/wheel.png" alt="" style="animation: initial" />
            </div>
            <div class="wheel10b" style="display: none">
              <img src="./images/飞艇/wheel2.png" alt="" />
            </div>
            <div class="wind" style="opacity: 1; display: block">
              <img src="./images/飞艇/wind.png" width="162" height="55" alt="" />
            </div>
            <div class="flame" style="opacity: 1; display: none">
              <img src="./images/飞艇/flame.png" width="51" height="12" alt="" />
            </div>
          </div>
          <div class="car4" id="car4" style="left: 1166px; top: 106px">
            <img src="./images/飞艇/car4.png" alt="" />
            <div class="wheel10a" style="display: block">
              <img src="./images/飞艇/wheel.png" alt="" style="animation: initial" />
            </div>
            <div class="wheel10b" style="display: none">
              <img src="./images/飞艇/wheel2.png" alt="" />
            </div>
            <div class="wind" style="opacity: 1; display: block">
              <img src="./images/飞艇/wind.png" width="154" height="56" alt="" />
            </div>
            <div class="flame" style="opacity: 1; display: none">
              <img src="./images/飞艇/flame.png" width="51" height="12" alt="" />
            </div>
          </div>
          <div class="car5" id="car5" style="left: 1153px; top: 151px">
            <img src="./images/飞艇/car5.png" alt="" />
            <div class="wheel10a" style="display: block">
              <img src="./images/飞艇/wheel.png" alt="" style="animation: initial" />
            </div>
            <div class="wheel10b" style="display: none">
              <img src="./images/飞艇/wheel2.png" alt="" />
            </div>
            <div class="wind" style="opacity: 1; display: block">
              <img src="./images/飞艇/wind.png" width="158" height="57" alt="" />
            </div>
            <div class="flame" style="opacity: 1; display: none">
              <img src="./images/飞艇/flame.png" width="53" height="13" alt="" />
            </div>
          </div>
          <div class="car6" id="car6" style="left: 1139px; top: 204px">
            <img src="./images/飞艇/car6.png" alt="" />
            <div class="wheel10a" style="display: block">
              <img src="./images/飞艇/wheel.png" alt="" style="animation: initial" />
            </div>
            <div class="wheel10b" style="display: none">
              <img src="./images/飞艇/wheel2.png" alt="" />
            </div>
            <div class="wind" style="opacity: 1; display: block">
              <img src="./images/飞艇/wind.png" width="161" height="59" alt="" />
            </div>
            <div class="flame" style="opacity: 1; display: none">
              <img src="./images/飞艇/flame.png" width="53" height="13" alt="" />
            </div>
          </div>
          <div class="car7" id="car7" style="left: 1128px; top: 256px">
            <img src="./images/飞艇/car7.png" alt="" />
            <div class="wheel10a" style="display: block">
              <img src="./images/飞艇/wheel.png" alt="" style="animation: initial" />
            </div>
            <div class="wheel10b" style="display: none">
              <img src="./images/飞艇/wheel2.png" alt="" />
            </div>
            <div class="wind" style="opacity: 1; display: block">
              <img src="./images/飞艇/wind.png" width="163" height="63" alt="" />
            </div>
            <div class="flame" style="opacity: 1; display: none">
              <img src="./images/飞艇/flame.png" width="55" height="14" alt="" />
            </div>
          </div>
          <div class="car8" id="car8" style="left: 1105px; top: 312px">
            <img src="./images/飞艇/car8.png" alt="" />
            <div class="wheel10a" style="display: block">
              <img src="./images/飞艇/wheel.png" alt="" style="animation: initial" />
            </div>
            <div class="wheel10b" style="display: none">
              <img src="./images/飞艇/wheel2.png" alt="" />
            </div>
            <div class="wind" style="opacity: 1; display: block">
              <img src="./images/飞艇/wind.png" width="165" height="65" alt="" />
            </div>
            <div class="flame" style="opacity: 1; display: none">
              <img src="./images/飞艇/flame.png" width="55" height="14" alt="" />
            </div>
          </div>
          <div class="car9" id="car9" style="left: 1084px; top: 370px">
            <img src="./images/飞艇/car9.png" alt="" />
            <div class="wheel10a" style="display: block">
              <img src="./images/飞艇/wheel.png" alt="" style="animation: initial" />
            </div>
            <div class="wheel10b" style="display: none">
              <img src="./images/飞艇/wheel2.png" alt="" />
            </div>
            <div class="wind" style="opacity: 1; display: block">
              <img src="./images/飞艇/wind.png" width="177" height="72" alt="" />
            </div>
            <div class="flame" style="opacity: 1; display: none">
              <img src="./images/飞艇/flame.png" width="57" height="15" alt="" />
            </div>
          </div>
          <div class="car10" id="car10" style="left: 1067px; top: 431px">
            <img src="./images/飞艇/car10.png" alt="" />
            <div class="wheel10a" style="display: block">
              <img src="./images/飞艇/wheel.png" alt="" style="animation: initial" />
            </div>
            <div class="wheel10b" style="display: none">
              <img src="./images/飞艇/wheel2.png" alt="" />
            </div>
            <div class="wind" style="opacity: 1; display: block">
              <img src="./images/飞艇/wind.png" width="177" height="72" alt="" />
            </div>
            <div class="flame" style="opacity: 1; display: none">
              <img src="./images/飞艇/flame.png" width="58" height="16" alt="" />
            </div>
          </div>
        </div>

        <div class="trafficlight" id="trafficlight" ref="trafficlight" style="display: block">
          <div class="countdownnum" style="display: block">{{treasu_data.date_m}}:{{treasu_data.date_s}}</div>
          <div class="countdownnum2" style="display: block">{{treasu_data.date_ms}}</div>
        </div>
      </div>

      <div class="resultpage page2" ref="resultpage" style="display: none">
        <div class="resultitm result1" id="result1" style="opacity: 1">
          <img src="./images/result1.png" width="226" height="177" alt="" />
        </div>
        <div class="resultitm result2" id="result2" style="opacity: 1">
          <img src="./images/result2.png" width="258" height="139" alt="" />
        </div>
        <div class="resultitm result3" id="result3" style="opacity: 1">
          <img src="./images/result3.png" width="173" height="112" alt="" />
        </div>

        <div class="resultitm resultcar2" id="resultcar2" style="opacity: 1; left: 81px; top: 287px">
          <img src="./images/飞艇/car10.png" ref="img_resultcar2" width="300" height="150" alt="" class="resultcarimg2" />
        </div>
        <div class="resultitm resultcar3" id="resultcar3" style="opacity: 1; left: 859px; top: 291px">
          <img src="./images/飞艇/car1.png" ref="img_resultcar3" width="270" height="130" alt="" class="resultcarimg3" />
        </div>
        <div class="resultitm resultcar1" id="resultcar1" style="opacity: 1; left: 420px; top: 328px">
          <img src="./images/飞艇/car5.png" ref="img_resultcar1" width="420" height="190" alt="" class="resultcarimg1" />
        </div>
      </div>

      <!-- <div class="footer">
                <div class="footer1">
                    <div class="footer1_2">期号:<span id="nextdrawtime" style="display: inline-block;">52337180</span></div>
                </div>
                <div class="footer2">
                    <div class="footer2_1">冠亚和:</div>
                    <div class="footer2_2" id="stat1_1">11</div>
                    <div class="footer2_2" id="stat1_2">小</div>
                    <div class="footer2_2" id="stat1_3">单</div>
                </div>
                <div class="footer3">
                    <div class="footer2_1">龙虎:</div>
                    <div class="footer2_2" id="stat2_1">虎</div>
                    <div class="footer2_2" id="stat2_2">龙</div>
                    <div class="footer2_2" id="stat2_3">虎</div>
                    <div class="footer2_2" id="stat2_4">虎</div>
                    <div class="footer2_2" id="stat2_5">虎</div>
                </div>
            </div> -->

      <div class="flashlight" style="display: none"></div>
    </div>
  </div>
</template>
<script>

import { countTime } from './js/myAnimation.js'

export default {
  name: 'Feiting',
  props: {
    code: String
  },
  data () {
    return {
      treasu_data: {
        etime: 0, // 开奖时间时间戳
        date_m: '00',
        date_s: '00',
        date_ms: '00'
      },
      part_num: 242, // 期数
      numArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], // 默认开奖号码
      result_Num_Array: [6, 10, 3, 4, 5, 2, 7, 8, 9, 1], // 实际开奖号码
      roaditm_defaultX: -1300, //  背景X轴
      roadstart_defaultX: 60, // 终点线位置X轴
      both_seelp: 24.02, // 船速度,
      bg_seelp: 20, // 背景速度
      current_x: 0, // 当前已经走了多远 px
      last_one_both_node: null, // 上次第一名的船
      gameTimes: 30 // 循环次数
    }
  },
  created () {

  },
  mounted () {
    this.requestGetLive()
    // this.reGame()
    // this.gameBegain()
    // this.gameOver()
  },
  methods: {
    // 接口请求
    requestGetLive () {
      /// !!!接口数据对不上这个动画
      this.$api.live(this.code).then(res => {
        // this.treasu_data.etime = parseInt(res.data.opentimestamp) * 1000
        // this.part_num = res.data.expect
        // this.reGame()
        console.log(res.data)
      }).then(err => {
        console.log(err)
      })
    },
    // 重启游戏
    reGame () {
      // 获得开奖时间
      var date = new Date()
      this.treasu_data.etime = date.getTime() + 3000

      var end = this.treasu_data.etime
      var that = this

      // 启动定时器
      countTime(end, function shuaxin (date_m, date_s, date_ms) {
        that.treasu_data.date_m = date_m
        that.treasu_data.date_s = date_s
        that.treasu_data.date_ms = date_ms
      }, function daoshijian () {
        that.treasu_data.date_ms = '00'
        console.log('倒计时结束，开奖')

        that.$refs.trafficlight.style.display = 'none'

        that.gameBegain()
      })
    },
    // 游戏开始
    gameBegain () {
      var setTimeoutId2
      var setTimeoutId3
      var that = this

      // 最长距离
      const childNodes = this.$refs.cars.childNodes
      var lastObj = childNodes[childNodes.length - 1]
      this.current_x = parseInt(lastObj.style.left)

      // 隐藏起点线
      setTimeout(() => {
        this.$refs.roadstart.style.display = 'none'
      }, 500)

      setTimeoutId2 = setInterval(function (params) {
        // 随机号码
        that.suijiNum()
        // 移动船
        that.moveBoth()

        if (that.gameTimes <= 0) {
          clearTimeout(setTimeoutId2)
          // 比赛结束
          console.log('比赛结束')

          that.gameOver()
          return
        }

        that.gameTimes--
      }, 300)

      setTimeoutId3 = setInterval(function (params) {
        // 移动背景
        that.movebg()

        if (that.gameTimes <= 0) {
          clearTimeout(setTimeoutId3)
        }
      }, 30)
    },
    // 游戏结束
    gameOver () {
      // 显示起点线
      this.$refs.roadstart.style.display = 'block'
      this.$refs.roadstart.style.left = this.roadstart_defaultX + 'px'

      const childNodes = this.$refs.cars.childNodes
      for (let index = 0; index < childNodes.length; index++) {
        const element = childNodes[index]
        var left = parseInt(element.style.left)
        // 补充斜线的差距
        left -= index * 17
        element.style.left = left + 'px'
      }

      setTimeout(() => {
        this.awards()
      }, 3000)
    },
    // 颁奖
    awards () {
      this.$refs.scenary.style.display = 'none'
      this.$refs.road.style.display = 'none'
      this.$refs.resultpage.style.display = 'block'

      var arr = this.result_Num_Array
      this.$refs.img_resultcar1.src = require('./images/飞艇/' + 'car' + arr[0] + '.png')
      this.$refs.img_resultcar2.src = require('./images/飞艇/' + 'car' + arr[1] + '.png')
      this.$refs.img_resultcar3.src = require('./images/飞艇/' + 'car' + arr[2] + '.png')

      setTimeout(() => {
        this.$router.go(0)
      }, 2000)
    },
    // 移动背景
    movebg () {
      var left = parseInt(this.$refs.roaditm.style.left)
      if (left >= -100) {
        left = this.roaditm_defaultX
      }
      left += this.bg_seelp
      this.$refs.roaditm.style.left = left + 'px'
      this.$refs.scenaryitm.style.left = left + 'px'
      // console.log(left)
    },
    // 随机号码
    suijiNum () {
      this.numArray.sort(function () {
        return (0.5 - Math.random())
      })

      // 最后一次(开奖)
      if (this.gameTimes <= 0) {
        this.numArray = this.result_Num_Array
      }

      // console.log(this.$refs.position.childNodes)
      for (let index = 0; index < this.numArray.length; index++) {
        const element = this.numArray[index]
        const node = this.$refs.position.childNodes[index]

        const posX = element * 64 - 64
        node.style = 'background-position: 0px ' + -posX + 'px'
        // console.log(element)
      }
      console.log('随机号码')
    },
    // 移动船
    moveBoth () {
      const childNodes = this.$refs.cars.childNodes

      var one_num = this.numArray[0]

      if (this.last_one_both_node) { // 上次第一名的位置
        this.current_x = parseInt(this.last_one_both_node.style.left)
      }

      this.current_x -= this.both_seelp

      for (let index = 0; index < childNodes.length; index++) {
        const element = childNodes[index]

        // 这一轮应该加的速度
        var num = this.numArray.indexOf(index + 1) // 排名 // console.log(num)
        var seelp = num * this.both_seelp

        // 根据排名调整位置
        var left = this.current_x + seelp

        console.log(this.current_x + '=' + this.both_seelp + '=' + seelp + '=' + num)

        if ((one_num - 1) == index) {
          // 记录当前第一位号码 用于下一次
          this.last_one_both_node = element
        }
        element.style.left = left + 'px'
        // console.log(element)
      }
      console.log('移动船')
    }

    // moveBoth () {
    //   console.log()

    //   const childNodes = this.$refs.cars.childNodes
    //   // 当前第一名
    //   var one_num = this.numArray[0] - 1

    //   // console.log(childNodes)
    //   for (let index = 0;index < childNodes.length;index++)
    //   {
    //     const element = childNodes[index]

    //     var left = parseInt(element.style.left)
    //     left -= this.both_seelp
    //     // 加速
    //     if (one_num == index)
    //     {
    //       // const lastNode = childNodes[this.last_One_Num - 1]
    //       left = this.firstBothX()
    //       left -= this.both_seelp * 1.2
    //     }

    //     element.style.left = left + 'px'

    //     // console.log(element)
    //   }

    //   // 记录当前第一位号码 用于下一次
    //   // this.last_One_Num = this.numArray[0]

    // },
    // // 最前面的船的X轴
    // firstBothX () {
    //   const childNodes = this.$refs.cars.childNodes
    //   var arr = []
    //   for (let index = 0;index < childNodes.length;index++)
    //   {
    //     const element = childNodes[index]
    //     var left = parseInt(element.style.left)

    //     arr[index] = left
    //   }
    //   arr.sort(function (a, b) {
    //     return a - b
    //   })
    //   var minX = arr[0]

    //   return minX
    // },
  }
}
</script>

<style scoped src="./css/Feiting.css">
</style>
<style lang="scss" scoped>
.feiting {
  height: 500px;
}
</style>
